import {fetch} from 'whatwg-fetch';
import Injector from '../core/utils/Injector';
import {getPPApiUrl, getStratumApiUrl} from './http';
import {isURL} from './validationRules';
import {range, get, find} from 'lodash';
import store from '../redux/store';
import {readCookie} from '../util/TLDCookies';

const abortableFetch = ('signal' in new Request('')) ?
    window.fetch :
    fetch;

export function getPageForRow(index, pageSize) {
  return Math.ceil((index + 1) / pageSize);
}

export function getRowsForPage(pageNumber, pageSize) {
  const maxRow = (pageNumber * pageSize);
  return range(maxRow - pageSize, maxRow);
}

function doApiCall(
    url,
    method,
    data = {},
    headers = {},
    controller = null,
    fetchOptions = {},
    retryAttempt = 0,
) {
  const signal = (controller instanceof AbortController) ?
        controller.signal :
        null;

  let body = undefined;
  if (headers['Content-Type'] &&
        headers['Content-Type'].match(/multipart\/mixed.*/)) {
    body = data;
  } else {
    body = (['GET', 'HEAD'].includes(method)) ?
          undefined :
          JSON.stringify(data);
  }

  if (url==null) {
    throw new Error('null url passed to doApiCall');
  }

  return abortableFetch(url, {
    method,
    // cache: 'no-cache',
    mode: 'cors',
    credentials: 'include',
    headers,
    body,
    signal,
    ...fetchOptions,
  }).then((resp) => {
    if (resp.status === 401) {
      // Get refresh token
      const refreshToken = get(store.getState(), 'user.refreshToken');

      // attempt to refresh token
      return fetch(
          Injector.config('pp_api_endpoint') + '/token/refresh',
          {
            method: 'POST',
            cache: 'no-cache',
            mode: 'cors',
            credentials: 'include',
            body: JSON.stringify({refresh_token: refreshToken}),
          },
      ).then(() => {
        // token probably refreshed, make original request again
        return abortableFetch(url, {
          method,
          // cache: 'no-cache',
          mode: 'cors',
          credentials: 'include',
          headers,
          body,
          signal,
          ...fetchOptions,
        }).then((resp) => {
          if (resp.status === 401) {
            // ok - it didn't refresh
            return Promise.reject(resp);
          }

          return resp;
        }).catch((ex) => {
          if (ex.name === 'AbortError') {
            // request aborted, don't care
            return;
          }

          throw ex;
        });
      });
    }

    const {
      maxRetries,
      retryStatusCodes,
      minDelay,
    } = Injector.config('apiRetry');

    if (retryStatusCodes.includes(resp.status)) {
      if (retryAttempt >= maxRetries) {
        return Promise.reject(resp);
      }

      // Delay increases exponentially in relation to retry attempts
      const retryDelay = minDelay * Math.pow(2, retryAttempt);

      return new Promise((resolve) => {
        setTimeout(
            () => resolve(
                doApiCall(
                    url,
                    method,
                    data,
                    headers,
                    controller,
                    fetchOptions,
                    retryAttempt + 1,
                ),
            ),
            retryDelay,
        );
      });
    }

    if ([403, 404].includes(resp.status)) {
      return Promise.reject(resp);
    }

    return resp;
  }).catch((ex) => {
    if (ex.name === 'AbortError') {
      // request aborted, don't care
      return;
    }

    // caller can deal with everything else
    throw ex;
  });
}

export function doPPAuthenticatedApiCall(
    url,
    method,
    data = {},
    customHeaders = {},
    controller = null,
) {
  if (!isURL(url)) {
    url = getPPApiUrl(url);
  }

  // Are we impersonating a user?
  const impersonateUserEmail = readCookie('IMPERSONATE_USER_EMAIL');

  const headers = {
    'Content-Type': 'application/json',
    'Accept': 'application/json;version='+Injector.config('pp_api_version'),
    ...(impersonateUserEmail ? {'x-switch-user': impersonateUserEmail} : {}),
    ...customHeaders,
  };

  return doApiCall(url, method, data, headers, controller);
}


export function doStratumAuthenticatedApiCall(
    url,
    method,
    data = {},
    customHeaders = {},
    controller = null,
) {
  if (!isURL(url)) {
    url = getStratumApiUrl(url);
  }

  const token = get(store.getState(), 'user.stratum.token');
  const headers = {
    'Content-Type': 'application/json',
    'Accept': 'application/json',
    'Authorization': `Bearer ${token}`,
    ...customHeaders,
  };

  return doApiCall(url, method, data, headers, controller);
}

export function hasPermission(user, attribute, subject) {
  return get(
      find(user.permissions, {subject}),
      'actions',
      [],
  ).includes(attribute);
}
