import {RSAA} from 'redux-api-middleware';
import Injector from '../../core/utils/Injector';
import {readCookie} from '../../util/TLDCookies';
import store from '../../redux/store';

export function dispatchPPApiCall(
    dispatch, name, method, action, body,
    overrideTypes = [], attemptReauthOnFailure = true, headers = {},
    bodyTransform,
) {
  // Are we impersonating a user?
  const impersonateUserEmail = readCookie('IMPERSONATE_USER_EMAIL');

  return dispatchApiCall(
      dispatch, name,
      Injector.config('pp_api_endpoint') + action,
      method, body, overrideTypes,
      {
        ...headers,
        ...(impersonateUserEmail ? {'x-switch-user': impersonateUserEmail} : {}),
      },
      attemptReauthOnFailure,
      bodyTransform,
  );
}

function dispatchApiCall(
    dispatch, name, endpoint, method, body,
    overrideTypes = [], headers = {}, attemptReauthOnFailure = true,
    bodyTransform,
) {
  const action = generateApiCall({
    name, endpoint, method, body,
    overrideTypes, headers, bodyTransform,
  });

  if (typeof dispatch !== 'function') {
    console.log('Not a function!', dispatch);
    return;
  }

  const handleResponse = (response) => {
    if ('name' in response.payload && ['ApiError', 'RequestError'].includes(response.payload.name)) {
      return Promise.reject(response);
    }

    return Promise.resolve(response);
  };

  const tryRefreshing = (response) => {
    // if we've had something other than a 401, no point refreshing
    if (!attemptReauthOnFailure || !('status' in response.payload) || response.payload.status !== 401) {
      return Promise.reject(response);
    }

    // Get refresh token
    const refreshToken = store.getState()?.user?.refreshToken;

    const doRefresh = generateApiCall({
      name: 'REFRESH_TOKEN',
      endpoint: Injector.config('pp_api_endpoint') + '/token/refresh',
      method: 'POST',
      body: {
        refresh_token: refreshToken,
      },
    });

    return dispatch(doRefresh);
  };

  const ohWellWeTried = (response) => {
    if (name === 'GET_SELF' && (response.payload.status === 401 || response.payload.status === 403)) {
      // kill the session
      dispatch({type: 'NOT_LOGGED_IN'});
    }

    dispatch({type: `${name}_FAILURE`, payload: response.payload});
  };

  return dispatch(action).then(handleResponse).catch(
      (response) => {
        return tryRefreshing(response)
            .then(() => dispatch(action))
            .then((response) => handleResponse(response))
            .catch((response) => ohWellWeTried(response));
      },
  );
}

function generateApiCall({
  name, endpoint, method, body,
  overrideTypes = [], headers = {},
  bodyTransform = (body) => (JSON.stringify(body)),
}) {
  const types = Array(3);
  const typeNames = [`${name}_REQUEST`, `${name}_COMPLETE`, `${name}_UNSUCCESSFUL`];
  for (let i = 0; i < 3; i++) {
    types[i] = (!overrideTypes[i]) ?
        typeNames[i] :
        Object.assign({}, overrideTypes[i], {type: typeNames[i]});
  }

  return {
    [RSAA]: {
      endpoint, method,
      headers: {
        'Content-Type': 'application/json',
        'Accept': 'application/json;version='+Injector.config('pp_api_version'),
        ...headers,
      },
      body: bodyTransform(body),
      credentials: 'include',
      types,
    },
  };
}
