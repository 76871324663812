const excludeType = '!type';
const typesToExclude = ['CLOUDFLOW_MANAGER', 'PROOFHQ_MANAGER', 'FACADE'];

const initialState = {
  approvalStatus: [],
  client: [],
  dueFrom: null,
  dueTo: null,
  plannedEndFrom: null,
  plannedEndTo: null,
  name: [],
  project: [],
  status: [],
  type: [],
  [excludeType]: typesToExclude,
  user: [],
  workflowTemplate: [],
  late: false,
  onTime: false,
};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case 'Change task filter':
      return {
        ...state,
        ...action.payload,
        [excludeType]: typesToExclude,
      };
    case 'Replace task filter':
      return {
        client: [],
        type: [],
        late: false,
        ...action.payload,
        [excludeType]: typesToExclude,
      };
    default:
      return state;
  }
}
