import getPage from '../util/getPage';
import {getFilterUrl} from '../../util/Filter';
import {dispatchPPApiCall} from '../util/apiCallGenerator';
import {doPPAuthenticatedApiCall} from 'Util/Util';
import HttpBatcher from 'Util/HttpBatcher';
import {getPPApiUrl} from 'Util/http';
import {keyBy} from 'lodash';

export function getAllTaskStatuses() {
  return (dispatch) => {
    return dispatchPPApiCall(
        dispatch,
        'GET_TASK_STATUSES',
        'GET', '/taskstatuses',
    );
  };
}

export function getLineApprovals(lineId, pageNumber = 1) {
  return (dispatch, getState) => {
    const {tasks, pageSize} = getState().line;
    const meta = {line: lineId};

    return getPage(
        dispatch,
        'GET_LINE_APPROVALS',
        'GET', '/approvals',
        tasks, pageSize, pageNumber, `line=${lineId}&advanced=1`, meta,
    );
  };
}

export function getTasks(pageNumber = 1) {
  return (dispatch, getState) => {
    const {pages, pageSize} = getState().tasks;
    const current = getState().filters.task.current;

    return getPage(
        dispatch,
        'GET_TASKS',
        'GET', '/tasks',
        pages, pageSize, pageNumber, getFilterUrl(current).join('&'),
    );
  };
}

export function searchTasks(pageNumber = 1, refetch = false) {
  return (dispatch, getState) => {
    const {search, searchPages, searchPageSize} = getState().tasks;
    const current = getState().filters.task.current;

    return getPage(
        dispatch,
        'GET_TASKS_SEARCH',
        'GET', '/tasks/_/search',
        searchPages, searchPageSize, pageNumber,
        `search=${search}&myTasks=1&${getFilterUrl(current).join('&')}`, {term: search},
        refetch,
    );
  };
}

export function completeTask(taskId) {
  return (dispatch) => {
    // Are we completing single or multiple tasks?
    if (['string', 'number'].includes(typeof taskId)) {
      return dispatchPPApiCall(
          dispatch,
          'PATCH_TASK',
          'PATCH', `/tasks/${taskId}`,
          {'status': 'COMPLETE'},
      );
    } else if (taskId instanceof Array) {
      // Requesting
      dispatch({
        type: 'PATCH_TASK_REQUEST',
      });

      // Batch complete tasks
      const batcher = new HttpBatcher();
      const taskPatchRequests = taskId.map((id) => {
        return batcher.addRequest(
            getPPApiUrl(`/tasks/${id}`),
            'PATCH',
            JSON.stringify({
              status: 'COMPLETE',
            }),
        )
            .then((response) => response.json());
      });
      batcher.dispatch();

      // Await all calls to be finished (Updating only the first task in the action taskID array)
      Promise.all(taskPatchRequests).then((updatedTasks) => {
        const mainUpdateTask = updatedTasks.find(({id}) => id === Number(taskId[0]));

        // Patch complete
        dispatch({
          type: 'PATCH_TASK_COMPLETE',
          payload: mainUpdateTask,
        });
      }).catch((error) => {
        // Request error
        dispatch({
          type: 'PATCH_TASK_FAILURE',
          payload: error,
        });
      });
    }
  };
}

export function getTask(taskId) {
  return (dispatch) => {
    return dispatchPPApiCall(
        dispatch,
        'GET_TASK',
        'GET', `/tasks/${taskId}`,
        undefined, Array(3).fill({meta: {taskId}}),
    );
  };
}

export function getPageForTask(task) {
  return (dispatch, getState) => {
    return dispatchPPApiCall(
        dispatch,
        'GET_TASKS',
        'GET',
        `/tasks?size=${getState().tasks.pageSize}&task=${task}`,
        undefined, Array(3).fill({meta: {task, filters: ''}}),
    );
  };
}

// CLOUDFLOW_APPROVAL/PROOFHQ_APPROVAL TASKS (Workflow)
export const getLineApprovalsWorkflow = (lineId) => async (dispatch) => {
  // Update status
  dispatch({
    type: 'GET_LINE_APPROVALS_WORKFLOW_REQUEST',
  });

  // Get Workflow ID for the line
  const workflowID = await doPPAuthenticatedApiCall(`/v2/lines/${lineId}`, 'GET')
      .then((response) => response.json())
      .then((jsonResponse) => {
        return jsonResponse?.entity?.activeWorkflowId;
      }).catch((error) => {
        console.error(error);
        dispatch({
          type: 'GET_LINE_APPROVALS_WORKFLOW_FAILURE',
          payload: {error},
        });
      });

  // Get CLOUDFLOW_APPROVAL/PROOFHQ_APPROVAL tasks. (Related tasks)
  const relatedTasks = await doPPAuthenticatedApiCall(`/v2/tasks?tasktype=CLOUDFLOW_MANAGER,PROOFHQ_MANAGER&include=relatedTask&workflow=${workflowID}&limit=100`, 'GET')
      .then((response) => response.json())
      .then((jsonResponse) => {
        return jsonResponse?.includes?.relatedTask;
      }).catch((error) => {
        console.error(error);
        dispatch({
          type: 'GET_LINE_APPROVALS_WORKFLOW_FAILURE',
          payload: {error},
        });
      });

  // Match tasks with users
  const userIds = new Set(Object.values(relatedTasks).map(({userId}) => userId));
  const batcher = new HttpBatcher();
  const userRequests = userIds.map((userId) => {
    return batcher.addRequest(
        getPPApiUrl('/v2/users/'+userId),
        'GET',
    )
        .then((response)=>(response.json()))
        .then((response) => response?.entity);
  });
  batcher.dispatch();

  const users = await Promise.all([...userRequests])
      .then((allResponses) => {
        batcher.reset();
        return allResponses;
      }).catch((error) => dispatch({
        type: 'GET_LINE_APPROVALS_WORKFLOW_FAILURE',
        payload: {error},
      }));
  if (!Array.isArray(users)) return;
  const usersById = keyBy(users, 'id');

  // Dispatch merged data
  dispatch({
    type: 'GET_LINE_APPROVALS_WORKFLOW_COMPLETE',
    payload: Object.values(relatedTasks).map((task) => {
      return {
        ...task,
        user: usersById[task?.userId],
      };
    }),
  });
};
